import { createContext, useEffect, useState, useContext } from "react";
import { http } from '../../services/http';
import { dataMapper } from "../../utils/data-mapper";
import { ALLOW_TO_BYPASS } from './constants';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [openHrModal, setOpenHrModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isBypassUser, setIsByPassUser] = useState(false)


  const authFields = { Email: 'email', Nome: 'nome', acccessToken: 'accessToken' };

  const handleChangeBypassUser = (bypass) => {
    setIsByPassUser(bypass)
  }

  useEffect(() => {
    async function bootstrap() {
      if (localStorage.getItem('user')) {
        const loggedUser = dataMapper(
          authFields,
          JSON.parse(localStorage.getItem('user'))
        );
        console.log(loggedUser)
        handleChangeBypassUser(ALLOW_TO_BYPASS.find((email) => email === loggedUser.email))
        setUser(loggedUser);
      }
    }
    bootstrap();
  }, []);

  async function handleSignIn({ username, password, recaptcha }) {
    setIsLoading(true);
    setHasError(false);
    setLoginError('');

    if (!recaptcha) {
      setHasError('Recaptcha inválido')
      return
    }

    http.post('auth', { login: username, senha: password, recaptcha })
      .then(response => {
        const data = dataMapper(authFields, response.data);
        setUser(data);
        handleChangeBypassUser(ALLOW_TO_BYPASS.find((email) => email === data.email))
        localStorage.setItem('user', JSON.stringify(data));
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          setOpenHrModal(true);
          return;
        }

        setHasError(true);
        setLoginError('Usuário ou senha inválidos.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleCloseModal = () => {
    setOpenHrModal(false);
  }

  const handleLogOut = () => {
    setUser(undefined);
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider value={{
      user,
      openHrModal,
      isLoading,
      hasError,
      loginError,
      isBypassUser,
      handleSignIn,
      handleLogOut,
      handleCloseModal,
      setOpenHrModal,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
